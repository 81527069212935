<template>
  <div>
    <div class="locale">
      <h2>系统菜单</h2>
      <ol>
        <li><router-link to="/">后台</router-link></li>
        <li><a href="javascript:;">系统</a></li>
        <li><a href="javascript:;">菜单</a></li>
        <li>查询</li>
      </ol>
      <router-link class="ant-btn ant-btn-primary" to="/sys/menu/create"><a-icon type="plus"/> 添加菜单</router-link>
    </div>


        <div class="box">
          <div class="box-body">
            <a-empty v-if="menus.length == 0">
              <span slot="description"> 还没有任何菜单！</span>
              <router-link class="ant-btn ant-btn-primary" to="/sys/menu/create"><a-icon type="plus"/> 添加菜单</router-link>
            </a-empty>
            <table class="list blank" v-if="menus.length > 0">
              <tr>
                <th style="width: 120px;">排序</th>
                <th>菜单</th>
                <th>链接目标</th>
                <th>角色可见</th>
                <th>备注</th>
                <th style="width: 80px;">状态</th>
                <th style="width: 120px;"></th>
              </tr>
              <template v-for="menu1 in menus">
                <tr>
                  <td>{{menu1.index}}</td>
                  <td>
                    <div>{{menu1.name}}</div>
                  </td>
                  <td>
                    <div>{{menu1.target}}</div>
                  </td>
                  <td>
                    <label class="label" v-for="role in menu1.roles">{{role.name}}</label>
                  </td>
                  <td>{{menu1.remark}}</td>
                  <td>
                    <a-switch @change="onState(menu1)" size="small" :checked="menu1.state.value == STATE_ENABLE" />
                  </td>
                    <td>
                      <a-button-group size="small">
                        <a-tooltip placement="top" title="编辑">
                          <router-link class="ant-btn" :to="'/sys/menu/change/'+menu1.id"><a-icon type="edit" /></router-link>
                        </a-tooltip>

                        <a-tooltip placement="topRight" title="删除" v-if="menu1.children == null || menu1.children.length == 0">
                          <a-popconfirm placement="bottomLeft" title="确认要删除此菜单吗？" @confirm="remove(menu1)">
                            <a-button style="color:red;"><a-icon type="delete" /></a-button>
                          </a-popconfirm>
                        </a-tooltip>

                      </a-button-group>
                    </td>
                </tr>
                <template v-if="menu1.children && menu1.children.length">
                  
                  <template v-for="menu2 in menu1.children">
                    <tr>
                      <td>{{menu1.index}}.{{menu2.index}}</td>
                      <td style="padding-left: 16px;">
                        <div><b>| -- </b>{{menu2.name}}</div> 
                        <!-- <b>→</b> -->
                      </td>
                      <td>
                        <div>{{menu2.target}}</div>
                      </td>
                      <td>
                        <label class="label" v-for="role in menu2.roles">{{role.name}}</label>
                      </td>
                      <td>{{menu2.remark}}</td>
                  <td>
                    <a-switch @change="onState(menu2)" size="small" :checked="menu2.state.value == STATE_ENABLE" />
                  </td>
                        <td class="">
                          <a-button-group size="small">
                            <a-tooltip placement="top" title="编辑">
                              <router-link class="ant-btn" :to="'/sys/menu/change/'+menu2.id"><a-icon type="edit" /></router-link>
                            </a-tooltip>
                            <a-tooltip placement="topRight" title="删除" v-if="menu2.children == null || menu2.children.length == 0">
                              <a-popconfirm placement="bottomLeft" title="确认要删除此菜单吗？" @confirm="remove(menu2)">
                                <a-button style="color:red;"><a-icon type="delete" /></a-button>
                              </a-popconfirm>
                            </a-tooltip>
                          </a-button-group>
                        </td>
                    </tr>

                    <template v-if="menu2.children && menu2.children.length">
                      
                      <template v-for="menu3 in menu2.children">
                        <tr>
                          <td>{{menu1.index}}.{{menu2.index}}.{{menu3.index}}</td>
                          <td style="padding-left: 40px;">
                            <div><b>| -- </b>{{menu3.name}}</div>
                            <!-- <b>→→</b> -->
                          </td>
                          <td>
                            <div>{{menu3.target}}</div>
                          </td>
                          <td>
                            <label class="label" v-for="role in menu2.roles">{{role.name}}</label>
                          </td>
                          <td>{{menu3.remark}}</td>
                  <td>
                    <a-switch @change="onState(menu3)" size="small" :checked="menu3.state.value == STATE_ENABLE" />
                  </td>
                            <td class="">
                              <a-button-group size="small">
                                <a-tooltip placement="top" title="编辑">
                                  <router-link class="ant-btn" :to="'/sys/menu/change/'+menu3.id"><a-icon type="edit" /></router-link>
                                </a-tooltip>
                                <a-tooltip placement="topRight" title="删除">
                                  <a-popconfirm placement="bottomLeft" title="确认要删除此菜单吗？" @confirm="remove(menu3)">
                                    <a-button style="color:red;"><a-icon type="delete" /></a-button>
                                  </a-popconfirm>
                                </a-tooltip>
                              </a-button-group>
                            </td>
                        </tr>
                      </template>

                    </template>

                  </template>
                </template>
              </template>
            </table>
          </div>
        </div>



  </div>
</template>


<script>
  export default {
    name: 'Black',
    data() {
      return {
        menus: []
      }
    },
    mounted() {
      this.request();
    },

    methods: {
      request() {
        this.$get('/sys/menu/filter').then(res => {
          let ajax = res.data;
          if (ajax.data) {
            this.$util.imageField(ajax.data.menus);
            this.$util.timeFields(ajax.data.menus);
            this.menus = ajax.data.menus;
          }
        })
      },


        onState(item) {
          let url = (item.state.value == this.STATE_ENABLE) ? '/sys/menu/disable/' : '/sys/menu/enable/';
          this.$post(url+item.id).then(res=>{
            let ajax = res.data;
            if (ajax.code == 0) {
              this.request(this.last);
              // item.state = ajax.data.item.state;
            }
          });
        },


        remove(item) {
            // this.$confirm('确认要删除此菜单吗？', ()=>{
                this.$post('/sys/menu/remove/' + item.id).then(res=>{
                    let ajax = res.data;
                    if (ajax.code == 0) {
                        setTimeout(()=>{
                            this.request(this.last);
                        }, 500);
                    }
                });
            // })
        }
    }
  }
</script>

<style scoped lang="less">
  .ant-rate {
    font-size: 16px;
  }
  .ant-rate /deep/ li {
    margin-right: 2px;
  }


  td b {
    margin-right: 4px; font-weight: normal;
    font-style: normal; color: #ccc;
  }


</style>